.checkout-container {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
  margin-top: 120px;

  .bonus-bar {
    display: contents;

    .progress-bar {
      width: 200px;
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 10px;

      .progress {
        height: 100%;
        background-color: #007bff;
        border-radius: 10px;
      }
    }

    p {
      margin-bottom: 30px;
    }
  }

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;
    text-align: center;

    .header-block {
      text-transform: capitalize;
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 36px;
  }

  .country-select {
    margin-bottom: 10px;
    margin-top: 15px;

    .form-label {
      font-size: 18px;
      color: black;
      margin-right: 10px;
    }

    .form-select {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: black;
    }
  }

  .terms-checkbox {
    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: black;
      margin-bottom: 10px;

      .form-checkbox {
        width: 18px;
        height: 18px;
        border: 2px solid black;
        background-color: white;
        margin-right: 10px;
        border-radius: 3px;

        &:checked {
          background-color: black;
          border: 2px solid black;

          &:before {
            content: "\2713";
            color: white;
            font-size: 14px;
            position: absolute;
            top: 1px;
            left: 4px;
          }
        }
      }

      .Acept_Terms {
        text-decoration: underline;
        margin-left: 4px;
      }
    }
  }
}

.checkout-botton-container {
  margin-top: 15px;
}

@media only screen and (max-width: 800px) {
  .checkout-container {
    width: 80%;
  }
}

@media only screen and (max-width: 650px) {
  .checkout-container {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .checkout-container {
    .checkout-header {
      .header-block {
        span {
          font-size: smaller;
        }
      }
    }

    .total {
      font-size: 26px;
    }
  }
}
