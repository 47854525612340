.navigation {
  height: 100px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 5;

  #container1 {
    display: flex;
  }

  .burger {
    display: none;
  }

  .burger div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px;
  }

  .logo-container {
    left: 0;
    height: 100%;
  }

  .nav-links-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .container_Search {
      padding-left: 15px;
      padding-top: 4px;
      padding-right: 15px;
      .ArrowSvg {
        display: none;
      }
    }

    .container_Search_Open {
      .ArrowSvg {
        display: none;
      }
    }

    .container2 {
      display: flex;
    }

    .nav-link {
      padding: 10px 15px;
      cursor: pointer;
    }

    .social_list {
      display: none;
    }
  }
}

.overlay {
  visibility: hidden;
}

.overlay-show {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: grey;
  visibility: visible;
  z-index: 3;
  opacity: 0.5;
}

@media only screen and (max-width: 1250px) {
  .ContainerButtons-gender {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .navigation {
    .nav-links-container {
      .container_Search {
        padding-left: 5px;
        padding-top: 4px;
        padding-right: 5px;
        .ArrowSvg {
          display: none;
        }
      }

      .container_Search_Open {
        background-color: white;
        left: 0;
        top: 0px;
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 3;
        padding: 30px 20px 20px 20px;
        display: flex;

        .ArrowSvg {
          display: flex;
          font-size: x-large;
          margin-top: 7px;
          margin-right: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .navigation {
    padding: 20px 20px;
    text-align: center;

    .burger {
      display: block;

      .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }

    .nav-links-container {
      .container_Search {
        .ArrowSvg {
          display: none;
        }
      }

      .container_Search_Open {
        background-color: unset;
        left: unset;
        top: unset;
        position: unset;
        width: unset;
        height: unset;
        z-index: unset;
        padding: unset;
        display: unset;

        .ArrowSvg {
          display: none;
          font-size: unset;
          margin-top: unset;
          margin-right: unset;
        }
      }

      .container2_inicial {
        visibility: hidden;
        display: none;
      }

      .container2_hidden {
        display: flex;
        position: absolute;
        height: calc(100vh - 100px);
        right: 0;
        width: 85%;
        top: 100px;
        background-color: white;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.5s, transform 0.5s;
        z-index: 3;
      }

      @keyframes moving_back {
        0% {
          transform: translateX(0);
          opacity: 1;
        }
        100% {
          transform: translateX(100%);
          opacity: 0;
        }
      }

      .container2_visible {
        display: flex;
        position: absolute;
        height: calc(100vh - 100px);
        right: 0;
        width: 85%;
        top: 100px;
        background-color: white;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.5s;
        z-index: 3;
      }

      @keyframes moving {
        0% {
          transform: translateX(100%);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .nav-link {
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px;
      }
      .nav-link:hover {
        background-color: #f3f3f3;
      }

      .footer_navbar {
        position: absolute;
        bottom: 0;
        left: 0;

        .social_list {
          display: flex;
          justify-content: center;
          list-style-type: none;
          padding: 0px;
          position: relative;
          bottom: 0;
          left: 0;
        }

        .social_list a {
          margin: 0 1em;
          color: black;
        }

        .social_list svg {
          font-size: 1.5em;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .navigation {
    .nav-links-container {
      .container_Search {
        .ArrowSvg {
          display: none;
        }
      }

      .container_Search_Open {
        background-color: white;
        left: 0;
        top: 0px;
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 3;
        padding: 30px 20px 20px 20px;
        display: flex;

        .ArrowSvg {
          display: flex;
          font-size: x-large;
          margin-top: 7px;
          margin-right: 8px;
        }
      }
    }
  }
}
