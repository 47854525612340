.BonusProductCartContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: 95%;
    object-fit: cover;
    margin-bottom: 5px;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 800px) {
    width: 45vw;
    height: 175px;
    flex-direction: column; /* Altere a direção do flex container para "column" */
    align-items: center; /* Centralize os elementos */
    height: auto;

    &:hover {
      .image {
        opacity: unset;
      }
    }
  }
}

.Footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 15px;

  @media screen and (max-width: 550px) {
    /*display: grid;*/
    flex-direction: column;
    font-size: 12px;
  }
}

.Name {
  width: 90%;
  font-weight: bold;

  @media screen and (max-width: 550px) {
    margin-bottom: 5px;
    font-size: 15px;
  }
}

.Price {
  color: inherit; // Cor padrão, pode ser substituída conforme necessário
  font-size: 17px;

  &[discounted="true"] {
    color: red; // Cor quando há desconto
    font-size: 14px; // Tamanho da fonte menor quando há desconto
    text-decoration: line-through; // Linha através do preço antigo quando há desconto
    margin-left: 5px; // Espaçamento à direita quando há desconto
  }

  @media screen and (max-width: 550px) {
    align-self: start;
    margin-top: 5px;

    &[discounted="true"] {
      font-size: 13px; // Tamanho da fonte menor quando há desconto no mobile
    }
  }
}

.card-more-information {
  margin-top: 10px;
  .card-colors-container {
    display: flex;
    align-items: center;

    .color-container {
      .color-button {
        width: 35px; /* Largura desejada para o botão */
        height: 35px; /* Altura desejada para o botão */
        border: none; /* Remova a borda do botão, se necessário */
        cursor: pointer; /* Mude o cursor para parecer um ponteiro ao passar o mouse */
        margin-top: 10px;
        margin-left: 7px;

        &.selecionado {
          outline: 1px solid black;
          outline-offset: 2px;
        }
      }
    }
  }

  .card-sizes-container {
    .tamanho-container {
      margin-bottom: 5px;

      .button {
        padding: 7px 20px;
        border-radius: 999px;
        color: black;
        background-color: white;
        border: 1px black solid;

        position: relative;
        margin-right: 10px;
        margin-left: 2px;
        cursor: pointer;

        &.selecionado {
          color: white;
          background-color: black;
        }

        &.esgotado {
          background-color: rgb(224, 224, 224);

          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;

            line {
              stroke: black;
              stroke-width: 1;
            }
          }
        }

        &.esgotado:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.button_container {
  display: flex;
  justify-content: center;
}
