.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .product-image {
    width: 120px;
    height: auto;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .product-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }
}
