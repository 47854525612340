.container-TermsConditions {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 120px;
  text-align: justify;

  span {
    font-size: x-large;
    line-height: 160%;
  }
}

@media only screen and (max-width: 1150px) {
  .container-TermsConditions {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 650px) {
  .container-TermsConditions {
    margin-left: 7%;
    margin-right: 7%;
  }
}

@media only screen and (max-width: 400px) {
}
