.App {
  height: "100%";
}

@font-face {
  font-family: montserrat;
  src: url(../public/Montserrat-Regular.ttf);
}
.montserrat {
  font-family: montserrat;
}
