@media only screen and (max-width: 540px) {
  .CartContainer {
    display: contents;
  }
}
.top-bar-bonus-products {
  display: flex;
  width: 100%;
  padding: 40px 40px 0 40px;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0;
  }

  span {
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 5px;
  }

  span:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    font-size: smaller;
  }
}

.bonus-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 50px;
  margin-bottom: 100px;
  justify-content: center;
  padding: 20px 40px;
}

@media only screen and (max-width: 800px) {
  .bonus-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 540px) {
  .bonus-container {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    row-gap: 0px;
    margin-bottom: 50px;
  }
  .top-bar-bonus-products {
    padding: 40px 20px 0 20px;
    h1 {
      font-size: 1rem;
    }

    span {
      font-size: 0.6rem;
    }

    svg {
      font-size: x-small;
    }
  }

  .bonus-container {
    padding: 20px 20px;
  }

  .button_container {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
