@mixin fade-in($time: 0.2s, $timing-function: ease-in-out) {
  transition: opacity $time $timing-function;

  &.loaded {
    opacity: 1;
  }
}

.container-about {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
  text-align: center;
  margin-top: 120px;

  span {
    font-size: x-large;
    line-height: 160%;

    a {
      text-decoration: underline;
      margin-left: 6px;
    }
  }

  img {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 60px;
    width: 80%;
    height: auto;
    @include fade-in(0.2s, ease-in-out);
  }
}

@media only screen and (max-width: 1150px) {
  .container-about {
    margin-left: 10%;
    margin-right: 10%;

    img {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .container-about {
    span {
      font-size: large;
    }
  }
}

@media only screen and (max-width: 400px) {
}
