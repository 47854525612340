.container-orders {
  margin-top: 160px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .user-info {
    margin-bottom: 30px;
    font-size: 18px;
    color: #444;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }

  .orders-list {
    .order-item {
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
      }

      h3 {
        margin-bottom: 15px;
        color: #007bff;
        font-size: 20px;
      }

      .order-additional-info {
        margin-top: 1rem;
      }

      .order-additional-info h4 {
        margin-bottom: 0.3rem;
      }

      .order-additional-info p {
        margin: 0;
      }

      .toggle-address-btn {
        margin-top: 1rem;
        background: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 16px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }

      .order-address {
        background-color: #e9f5ff;
        border: 1px solid #cce4ff;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
        font-size: 16px;
      }

      .products-list {
        margin-top: 15px;

        .product-item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .product-image {
            width: 120px;
            height: auto;
            margin-right: 20px;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .product-details {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          p {
            margin: 0;
            font-size: 16px;
          }
        }
      }

      p {
        font-size: 16px;
      }
    }
  }

  .orders-advice {
    margin-top: 30px;
    padding: 15px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 8px;
    font-size: 16px;

    a {
      color: #007bff;
      text-decoration: underline;
    }
  }

  .no-orders {
    padding: 20px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
  }
}
