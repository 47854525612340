.footer {
  display: grid;
  margin-top: 60px;
  color: black;
  padding-top: 1em;
  text-align: center;
  z-index: 2;
  background-color: rgb(225, 225, 225);
  justify-content: center;
}

.social_list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0px;
}

.social_list a {
  margin: 0 1em;
  color: black;
}

.social_list svg {
  font-size: 1.5em;
  cursor: pointer;
}

.To-TermsConditions {
  font-weight: bold;
}

.TeamMail {
  margin-top: 7px;
}

.PaymentCardsContainer {
  margin-top: 17px;
  display: flex;
  justify-content: center;
  gap: 7px;
  padding-bottom: 10px;
  svg {
    font-size: x-large;
  }
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(153, 153, 153); /* Adicione a linha separadora */
  padding: 10px 65px;
  width: 95vw;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 10px;
}

.footer-links a {
  margin: 5px 0;
}

.footer-rights {
  text-align: center; /* Alinhe o texto de direitos autorais ao centro */
}

.copy_right {
  font-size: small;
  margin-top: 1em;
}

.copy_right span {
  font-weight: bold;
}

/* Media queries para tornar o rodapé responsivo */
@media (min-width: 650px) {
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-links {
    flex-direction: row;
    align-items: flex-start;
  }

  .footer-links a {
    margin: 0 15px; /* Espaçamento horizontal entre links */
  }
}
