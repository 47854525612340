.container-navigation-banner {
  background-color: rgb(224, 224, 224);
  z-index: 4;
  width: 100%;
  height: 40px;
  position: fixed;
  text-align: center;
  border-top: 1px solid rgb(207, 207, 207);
  top: 100px;

  transition: transform 0.5s ease-in-out;
  transform: translateY(
    -100%
  ); /* Definindo o banner para estar inicialmente fora da tela */

  &.fadeIn {
    transform: translateY(-100%); /* Animando a entrada do banner */
  }

  &.fadeOut {
    transform: translateY(0); /* Animando a saída do banner */
  }

  .text-navigation-banner {
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13.5px;
    font-family: sans-serif;
  }
}
