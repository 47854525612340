.product-page-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 120px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  .product-page-left {
    position: relative;
    text-align: center;

    .image-container {
      position: relative;
      display: inline-block;
      text-align: center;

      .arrow-button {
        width: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 54px;
        background: none;
        border: none;
        cursor: pointer;
        color: white;

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }
      }

      img {
        cursor: pointer;
        height: 100%;
        max-height: 400px;
        display: block;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;

        @media screen and (max-width: 450px) {
          width: 100%;
          height: unset;
          max-height: unset;
          display: unset;
        }
      }
    }
  }

  .product-page-right {
    .discount-container {
      width: 100%;

      .discount-label {
        background-color: red; /* Cor de fundo do desconto */
        color: white; /* Cor do texto do desconto */
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px; /* Tamanho da fonte do desconto */
        padding: 5px 10px;
        border-radius: 3px;
        display: table;
      }

      .discounted-price {
        text-decoration: line-through; /* Riscar o preço antigo */
        color: red; /* Cor vermelha */
        font-size: 12px; /* Tamanho menor da fonte do preço antigo */
      }
    }

    .tamanho-container {
      margin-bottom: 5px;

      .button {
        padding: 7px 20px;
        border-radius: 999px;
        color: black;
        background-color: white;
        border: 1px black solid;

        position: relative;
        margin-right: 10px;
        margin-left: 2px;
        cursor: pointer;

        &.selecionado {
          color: white;
          background-color: black;
        }

        &.esgotado {
          background-color: rgb(224, 224, 224);

          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;

            line {
              stroke: black;
              stroke-width: 1;
            }
          }
        }

        &.esgotado:hover {
          cursor: not-allowed;
        }
      }
    }

    .To-SizeGuide-productPage {
      text-decoration: underline;
    }

    .color-container {
      .color-button {
        width: 35px; /* Largura desejada para o botão */
        height: 35px; /* Altura desejada para o botão */
        border: none; /* Remova a borda do botão, se necessário */
        cursor: pointer; /* Mude o cursor para parecer um ponteiro ao passar o mouse */
        margin-top: 10px;
        margin-left: 7px;

        &.selecionado {
          outline: 1px solid black;
          outline-offset: 2px;
        }
      }
    }

    .alertContainer {
      margin-top: 2px;

      span {
        color: red;
        display: block;
        font-size: small;
      }
    }
  }
}
