.notification {
  position: fixed;
  top: 20px; /* Ajuste a posição vertical conforme necessário */
  right: 300px; /* Comece fora da tela */
  background-color: green;
  color: white;
  padding: 20px 20px;
  border-radius: 4px;
  animation: slide-in 0.5s forwards, slide-out 0.5s 1.5s forwards;
  z-index: 999;
  align-items: center;
  display: flex;

  svg {
    color: white;
    margin-left: 5px;
  }
}

@keyframes slide-in {
  from {
    right: -300px;
  }
  to {
    right: 20px; /* Posição final na tela */
  }
}

@keyframes slide-out {
  from {
    right: 20px; /* Posição final na tela */
  }
  to {
    right: -300px; /* Fora da tela */
  }
}
