.category-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 50px;
  justify-content: center;
  padding: 0 20px;
}

.category-title {
  font-size: 38px;
  margin-bottom: 25px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .category-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 40px;
  }
}

@media only screen and (max-width: 650px) {
  .category-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .category-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .category-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
