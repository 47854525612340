.container-admin {
  margin-top: 160px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  span {
    font-size: x-large;
    line-height: 160%;

    a {
      text-decoration: underline;
      margin-left: 6px;
    }
  }
}
