.authentication-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto;
  margin-top: 120px;
}

@media only screen and (max-width: 1000px) {
  .authentication-container {
    width: 800px;
  }
}

@media only screen and (max-width: 800px) {
  .authentication-container {
    display: contents;
    padding: 20px 10px;
  }
}
